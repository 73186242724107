export default {
  legacy: false,
  locale: 'cs',
  fallbackLocale: 'cs',
  numberFormats: {
    cs: {
      currency: {
        style: 'currency',
        currency: 'CZK',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
      },
    },
  } as Record<string, Record<string, Intl.NumberFormatOptions>>,
}
